import * as React from "react"
import styled, {css} from "styled-components";
import {Container} from '../theme/Grid';
import {colors} from "../utils/theme";
import {useWindowScroll, useWindowSize} from "react-use";
import {breakpoints, size} from "../utils/breakpoints";
import Logo from "./Logo";
import MobileMenu from "./MobileMenu";
import Menu from "./Menu";

const StyledHeader = styled.header`
  display: flex;
  flex-direction: row;
  transition: all .5s;
  position: relative;
  align-items: center;

  ${breakpoints.small} {
    height: 100px;
  }

  ${breakpoints.large} {
    height: 128px;
  }

  ${({fixed}) => fixed && css`
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
    padding: 10px 0;
    background: ${colors.primaryColor};
    width: 100%;
    z-index: 10;
    top: 0;
    left: 0;
    position: sticky;
  `}`

const HeaderWrapper = styled(Container)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
`

const Header = () => {
    const {y} = useWindowScroll()
    const {width} = useWindowSize();
    const fixedHeader = y > 48;
    const displayMobileHeader = width < size.large;
    return (
        <StyledHeader fixed={fixedHeader}>
            <HeaderWrapper>
                <Logo fixed={fixedHeader}/>
                {!displayMobileHeader && <Menu/>}
                {displayMobileHeader && <MobileMenu/>}
            </HeaderWrapper>
        </StyledHeader>
    )
};

export default Header;
import logo from "../images/logo.png";
import * as React from "react";
import styled from "styled-components";
import {breakpoints} from "../utils/breakpoints";

const LogoWrapper = styled.div`
`

const StyledLogo = styled.img`
  height: auto;
  transition: all .3s;
  position: absolute;
  top: 0;

  ${breakpoints.small} {
    width: 150px;
    top: 18px;
    ${({fixed}) => fixed && `top: 18px`};
  }

  ${breakpoints.large} {
    ${({fixed}) => fixed ? `width: 220px; top: 16px` : `width: 270px`};
  }
`;
const Logo = ({fixed = false}) => <LogoWrapper fixed={fixed}><StyledLogo fixed={fixed} src={logo} alt={'Picars'}/></LogoWrapper>


export default Logo;
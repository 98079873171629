import * as React from "react"
import styled from "styled-components";
import {colors} from "../utils/theme";
import {rgba} from "polished";
import {Container} from "../theme/Grid";
import {changeLocale} from 'gatsby-plugin-react-intl';

const LINKEDIN_URL = 'https://pl.linkedin.com/company/picarspl';
const FACEBOOK_URL = 'https://www.facebook.com/PicarsStarachowice';
const TopBarWrapper = styled(Container)`
  border-bottom: 1px solid ${rgba(colors.textColor, 0.5)};
  display: flex;
  margin-bottom: 30px;
  padding: 15px 0;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`

const List = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;

  li {
    margin-left: 10px;
    font-size: 14px;
    font-weight: 300;
    text-transform: uppercase;

    a {
      color: ${colors.textColor};
      text-decoration: none;

      &:hover {
        color: ${colors.secondaryColor}
      }
    }

  }
`
const LanguageSwitcher = styled(List)`
  margin-left: 20px;

  li {
    padding-right: 10px;
    border-right: 1px solid ${colors.textColor};
    cursor: pointer;
    &:last-child {
      padding-right: 0;
      border-right: 0;
    }

    &:hover {
      color: ${colors.secondaryColor}
    }
  }`


export const TopBar = () => {

    const changeLang = (lang) => () => {
        changeLocale(lang);
    }
    return (<TopBarWrapper>
        <List>
            <li><a href={LINKEDIN_URL} target='_blank'>LinkedIn</a></li>
            <li><a href={FACEBOOK_URL} target='_blank'>Facebook</a></li>
        </List>
        <LanguageSwitcher>
            <li onClick={changeLang('pl')}>PL</li>
            <li onClick={changeLang('de')}>DE</li>
            <li onClick={changeLang('en')}>EN</li>
        </LanguageSwitcher>
    </TopBarWrapper>)
}

export default TopBar;
import MENU_ITEMS from "../utils/menu";
import * as React from "react";
import styled from "styled-components";
import {colors} from "../utils/theme";
import {Link} from "react-scroll";
import {FormattedMessage} from 'gatsby-plugin-react-intl';

const List = styled.ul`
  margin: 0;
  list-style: none;
  display: flex;
  flex-direction: row;
  font-size: 16px;
  font-weight: 300;
  text-transform: uppercase;
  padding: 0;

  li {
    margin: 0 9px;
    
    &:last-child{
      margin-right: 0;
    }

    &:hover {
      color: ${colors.secondaryColor};
      transition: all .1s;
      cursor: pointer;
    }

    a {
      transition: all .3s;

      &.active {
        color: ${colors.secondaryColor};
      }
    }
  }
`

const Menu = () => (<nav>
    <List>
        {MENU_ITEMS
            .map(({label, href}) =>
                <li key={label.toLowerCase()}>
                    <Link
                        activeClass="active"
                        to={href}
                        spy={true}
                        smooth={true}
                        offset={-70}
                        duration={500}
                    ><FormattedMessage id={label}/> </Link></li>)}
    </List>
</nav>)

export default Menu;
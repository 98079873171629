import styled from "styled-components";
import {breakpoints, size} from "../utils/breakpoints";

export const Container = styled.div`
  display: flex;
  margin: 0 auto;
  flex-direction: column;

  ${breakpoints.small} {
    padding: 0 20px;
    width: 100%;
  }

  ${breakpoints.large} {
    padding: 0;
    width: ${size.large}px;
  }
`;

export const Column = styled.div`
  padding: 0 20px;

  ${breakpoints.small} {
    width: 100%;
  }

  ${breakpoints.large} {
    width: ${({width}) => Math.round(width * 10) + '%'};
  }
`

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0 -20px;
  align-items: center;
  flex-wrap: wrap;

  ${breakpoints.small} {
    text-align: center;
    ${({reverseOnMobile}) => reverseOnMobile && `
      flex-direction: column-reverse;
    `}
    justify-content: center

  }

  ${breakpoints.large} {
    justify-content: space-between;
  }

`
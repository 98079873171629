import {createGlobalStyle} from 'styled-components';
import {colors} from "../utils/theme";

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    background: ${colors.primaryColor};
    color: white;
    font-family: 'Fira Sans', Helvetica, Sans-Serif;

    * {
      box-sizing: border-box;
    }
  }
`;

export default GlobalStyle;
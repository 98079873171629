import MenuIcon from "@material-ui/icons/Menu";
import CloseIcon from "@material-ui/icons/Close";
import * as React from "react";
import styled, {css, keyframes} from "styled-components";
import {colors} from "../utils/theme";
import {useState} from "react";
import MENU_ITEMS from "../utils/menu";
import Logo from "./Logo";
import {Link} from "react-scroll";
import {FormattedMessage} from 'gatsby-plugin-react-intl';

const slide = keyframes`
  0% {
    left: -100%;
  }
  100% {
    left: 0;
  }
`

const slideAnimation = css`
  ${slide} .3s linear;
`

const ToggleButton = styled.button`
  border: 0;
  background: 0;
  outline: 0;
  color: ${colors.textColor};
  cursor: pointer;

  &:hover {
    color: ${colors.secondaryColor};
  }
`
const StyledMobileMenu = styled.div`
  //display: flex;
  position: fixed;
  top: 0;
  text-align: left;
  height: 100%;
  z-index: 2;
  background-color: ${colors.menuColor};
  left: 0;
  padding: 20px;
  width: 80%;
  animation: ${slideAnimation};

  ul {
    list-style: none;
    margin: 50px 0 0 0;
    padding: 0;
    text-align: left;

    li {
      text-transform: uppercase;
      font-size: 20px;
      padding: 10px;
      a {
        transition: all .3s;

        &.active {
          color: ${colors.secondaryColor};
        }
      }
    }
  }
`

const MobileMenuWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  position: fixed;
  z-index: 2;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, .2);
`

const MenuHeader = styled.div`
  justify-content: space-between;
  justify-items: center;
  align-items: center;
  display: flex;`

const MobileMenu = () => {
    const [isMobileMenuDisplayed, setMobileMenuDisplayed] = useState(false);
    const toggleMobileMenu = (event) => {
        event.stopPropagation();
        setMobileMenuDisplayed(!isMobileMenuDisplayed)
    }

    return (<>
        <ToggleButton onClick={toggleMobileMenu}>
            <MenuIcon fontSize={"large"}/>
        </ToggleButton>
        {isMobileMenuDisplayed && <>
            <MobileMenuWrapper onClick={toggleMobileMenu}/>
            <StyledMobileMenu>
                <MenuHeader>
                    <Logo/>
                    <ToggleButton onClick={toggleMobileMenu}>
                        <CloseIcon fontSize={"large"}/>
                    </ToggleButton>
                </MenuHeader>
                <ul>
                    {MENU_ITEMS
                        .map(({label, href}) =>
                            <li key={label.toLowerCase()}>
                                <Link
                                    activeClass="active"
                                    to={href}
                                    onClick={toggleMobileMenu}
                                    spy={true}
                                    smooth={true}
                                    offset={-70}
                                    duration={500}
                                ><FormattedMessage id={label}/></Link></li>)}
                </ul>
            </StyledMobileMenu>
        </>}
    </>)
}

export default MobileMenu;
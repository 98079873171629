const MENU_ITEMS = [
    {
        label: 'menu.about',
        href: 'o-nas'
    },
    {
        label: 'menu.transport',
        href: 'transport'
    },
    {
        label: 'menu.logistics',
        href: 'logistyka'
    },
    {
        label: 'menu.service',
        href: 'serwis'
    },
    {
        label: 'menu.sales',
        href: 'sprzedaz'
    },
    {
        label: 'menu.contact',
        href: 'kontakt'
    }
]

export default MENU_ITEMS;